export default {
  entering: {
    width: 56,
  },
  entered: {
    width: 56,
  },
  exiting: {
    width: 0,
  },
  exited: {
    width: 0,
  },
};
