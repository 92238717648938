import ShopgateTrackingTrustedTrackingSubscriber from '@shopgate/tracking-trusted/frontend/subscriptions/index.js';
import ShopgateTrackingShopgateAnalyticsSubscribers from '@shopgate/tracking-shopgate-analytics/frontend/src/subscriptions.js';
import ShopgateAuthFacebookSubscriber from '@shopgate/auth-facebook/frontend/subscriptions/index.js';
import ShopgateStorefrontStorefrontSubscribers from '@shopgate/storefront/frontend/subscribers/index.js';
import ShopgateConnectDualStackSyncPushMessageSubscription from '@shopgate/connect/frontend/streams.js';
import ShopgateConnectTrustedExternalLoginSubscription from '@shopgate/connect-trusted/frontend/ExternalLogin/subscribers.js';
import ShopgateConnectTrustedRegisterSubscription from '@shopgate/connect-trusted/frontend/Register/subscribers.js';
import ShopgateConnectTrustedCheckoutSubscription from '@shopgate/connect-trusted/frontend/Checkout/subscribers.js';
import ShopgateConnectTrustedGuestCheckoutSubscription from '@shopgate/connect-trusted/frontend/GuestCheckout/subscribers.js';

export default [
  ShopgateTrackingTrustedTrackingSubscriber,
  ShopgateTrackingShopgateAnalyticsSubscribers,
  ShopgateAuthFacebookSubscriber,
  ShopgateStorefrontStorefrontSubscribers,
  ShopgateConnectDualStackSyncPushMessageSubscription,
  ShopgateConnectTrustedExternalLoginSubscription,
  ShopgateConnectTrustedRegisterSubscription,
  ShopgateConnectTrustedCheckoutSubscription,
  ShopgateConnectTrustedGuestCheckoutSubscription,
];
