export const FB_LOGIN = 'FB_LOGIN';
export const FB_LOGIN_SUCCESS = 'FB_LOGIN_SUCCESS';
export const FB_LOGIN_FAILED = 'FB_LOGIN_FAILED';

export const FB_LOGOUT = 'FB_LOGOUT';
export const FB_LOGOUT_SUCCESS = 'FB_LOGOUT_SUCCESS';

export const FB_FETCH_CONFIG = 'FB_FETCH_CONFIG';
export const FB_FETCH_CONFIG_FAILED = 'FB_FETCH_CONFIG_FAILED';
export const FB_FETCH_CONFIG_SUCCESS = 'FB_FETCH_CONFIG_SUCCESS';
