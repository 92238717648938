import { pwaDidAppear$ } from '@shopgate/pwa-tracking/streams/app';
import getUser from '@shopgate/pwa-common/actions/user/getUser';

/**
 * GuestCheckout subscriptions.
 * @param {Function} subscribe The subscribe function.
 */
export default function guestCheckout(subscribe) {
  subscribe(pwaDidAppear$, ({ dispatch }) => {
    setTimeout(() => {
      dispatch(getUser());
    }, 1000);
  });
}
