import portalCollection from '@shopgate/pwa-common/helpers/portals/portalCollection';
import { hot } from 'react-hot-loader/root';
import { lazy } from 'react';

const ShopgateAuthFacebookFacebookButtonMenu = lazy(() => import('@shopgate/auth-facebook/frontend/components/FacebookForm/components/FacebookButton/index.jsx'))

const ShopgateAuthFacebookFacebookButtonLogin = lazy(() => import('@shopgate/auth-facebook/frontend/components/FacebookForm/index.jsx'))

const ShopgateStorefrontCartHidePaypalExpress = lazy(() => import('@shopgate/storefront/frontend/portals/CartHidePaypalExpress'))

const ShopgateConnectTrustedGuestCheckoutLink = lazy(() => import('@shopgate/connect-trusted/frontend/GuestCheckout/portals/RegisterLinkAfter/index.jsx'))

const ShopgateConnectTrustedGuestCheckoutLogout = lazy(() => import('@shopgate/connect-trusted/frontend/GuestCheckout/portals/NavMenuLogout/index.jsx'))

portalCollection.registerConfig({
 "@shopgate/auth-facebook/FacebookButtonMenu": {
  "path": "@shopgate/auth-facebook/frontend/components/FacebookForm/components/FacebookButton/index.jsx",
  "target": [
   "user-menu.container.before"
  ]
 },
 "@shopgate/auth-facebook/FacebookButtonLogin": {
  "path": "@shopgate/auth-facebook/frontend/components/FacebookForm/index.jsx",
  "target": [
   "page.login.form.before"
  ]
 },
 "@shopgate/storefront/CartHidePaypalExpress": {
  "path": "@shopgate/storefront/frontend/portals/CartHidePaypalExpress",
  "target": "legacy-paypal-express.cart"
 },
 "@shopgate/connect-trusted/GuestCheckoutLink": {
  "path": "@shopgate/connect-trusted/frontend/GuestCheckout/portals/RegisterLinkAfter/index.jsx",
  "target": "page.login.register-link.after"
 },
 "@shopgate/connect-trusted/GuestCheckoutLogout": {
  "path": "@shopgate/connect-trusted/frontend/GuestCheckout/portals/NavMenuLogout/index.jsx",
  "target": "nav-menu.logout"
 }
});

portalCollection.registerPortals({
  '@shopgate/auth-facebook/FacebookButtonMenu': hot(ShopgateAuthFacebookFacebookButtonMenu),
  '@shopgate/auth-facebook/FacebookButtonLogin': hot(ShopgateAuthFacebookFacebookButtonLogin),
  '@shopgate/storefront/CartHidePaypalExpress': hot(ShopgateStorefrontCartHidePaypalExpress),
  '@shopgate/connect-trusted/GuestCheckoutLink': hot(ShopgateConnectTrustedGuestCheckoutLink),
  '@shopgate/connect-trusted/GuestCheckoutLogout': hot(ShopgateConnectTrustedGuestCheckoutLogout),
});
