import ShopgateAuthFacebookDeDe from '@shopgate/auth-facebook/frontend/locale/de-DE.json';
import ShopgateAuthFacebookEnUs from '@shopgate/auth-facebook/frontend/locale/en-US.json';
import ShopgateAuthFacebookEsEs from '@shopgate/auth-facebook/frontend/locale/es-ES.json';
import ShopgateAuthFacebookFrFr from '@shopgate/auth-facebook/frontend/locale/fr-FR.json';
import ShopgateAuthFacebookItIt from '@shopgate/auth-facebook/frontend/locale/it-IT.json';
import ShopgateAuthFacebookNlNl from '@shopgate/auth-facebook/frontend/locale/nl-NL.json';
import ShopgateAuthFacebookPtPt from '@shopgate/auth-facebook/frontend/locale/pt-PT.json';
import ShopgateConnectTrustedLocaleEnUs from '@shopgate/connect-trusted/frontend/locale/en-US.json';
import ShopgateConnectTrustedLocaleDeDe from '@shopgate/connect-trusted/frontend/locale/de-DE.json';
import ShopgateConnectTrustedLocaleFrFr from '@shopgate/connect-trusted/frontend/locale/fr-FR.json';
import ShopgateConnectTrustedLocaleNlNl from '@shopgate/connect-trusted/frontend/locale/nl-NL.json';
import ShopgateConnectTrustedLocaleEsEs from '@shopgate/connect-trusted/frontend/locale/es-ES.json';
import ShopgateConnectTrustedLocaleItIt from '@shopgate/connect-trusted/frontend/locale/it-IT.json';
import ShopgateConnectTrustedLocalePtPt from '@shopgate/connect-trusted/frontend/locale/pt-PT.json';

export default {
  '@shopgate/auth-facebook/de-DE': ShopgateAuthFacebookDeDe,
  '@shopgate/auth-facebook/en-US': ShopgateAuthFacebookEnUs,
  '@shopgate/auth-facebook/es-ES': ShopgateAuthFacebookEsEs,
  '@shopgate/auth-facebook/fr-FR': ShopgateAuthFacebookFrFr,
  '@shopgate/auth-facebook/it-IT': ShopgateAuthFacebookItIt,
  '@shopgate/auth-facebook/nl-NL': ShopgateAuthFacebookNlNl,
  '@shopgate/auth-facebook/pt-PT': ShopgateAuthFacebookPtPt,
  '@shopgate/connect-trusted/locale/en-US': ShopgateConnectTrustedLocaleEnUs,
  '@shopgate/connect-trusted/locale/de-DE': ShopgateConnectTrustedLocaleDeDe,
  '@shopgate/connect-trusted/locale/fr-FR': ShopgateConnectTrustedLocaleFrFr,
  '@shopgate/connect-trusted/locale/nl-NL': ShopgateConnectTrustedLocaleNlNl,
  '@shopgate/connect-trusted/locale/es-ES': ShopgateConnectTrustedLocaleEsEs,
  '@shopgate/connect-trusted/locale/it-IT': ShopgateConnectTrustedLocaleItIt,
  '@shopgate/connect-trusted/locale/pt-PT': ShopgateConnectTrustedLocalePtPt,
};
