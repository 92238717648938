import { loginDidFail$ } from '@shopgate/pwa-common/streams/user';
import { EINCOMPLETELOGIN } from '@shopgate/pwa-core/constants/Pipeline';
import openPage from '@shopgate/pwa-core/commands/openPage';
import { pwaDidAppear$ } from '@shopgate/pwa-tracking/streams/app';
import event from '@shopgate/pwa-core/classes/Event';
import registerEvents from '@shopgate/pwa-core/commands/registerEvents';
import { syncUserData, getRedirectTarget } from './actions';

export default (subscribe) => {
  const incompleteLogin$ = loginDidFail$.filter(({ action }) => action.code === EINCOMPLETELOGIN);

  registerEvents(['userLoggedIn']);
  let eventCallback;

  // Open the external register page where the user can enter additional data.
  subscribe(incompleteLogin$, async ({ dispatch }) => {
    const { redirectTarget } = await dispatch(getRedirectTarget());

    /*
     * When external registration is completed
     * we need to sync the cake session with connect session.
     */
    eventCallback = () => dispatch(syncUserData());
    event.addCallback('userLoggedIn', eventCallback);

    openPage({
      src: `sgapi:${redirectTarget}`,
      previewSrc: 'sgapi:page_preview',
      targetTab: 'main',
      navigationBarParams: {
        type: 'default',
        leftButtonCallback: '',
      },
    });
  });

  const viewAppearAfterLogin$ = incompleteLogin$.mergeMap(() => pwaDidAppear$.take(1));

  // Clear event callback when pwa is active again.
  subscribe(viewAppearAfterLogin$, async () => {
    event.removeCallback('userLoggedIn', eventCallback);
  });
};
