import get from 'lodash/get';
import { appWillStart$ } from '@shopgate/pwa-common/streams/app';
import { redirects } from '@shopgate/pwa-common/collections';
import { LEGACY_URL } from '@shopgate/pwa-common/constants/Registration';
import { REGISTER_PATH } from '@shopgate/pwa-common/constants/RoutePaths';
import buildRegisterUrl from '@shopgate/pwa-common/subscriptions/helpers/buildRegisterUrl';

export default (subscribe) => {
  /**
   * @param {Object} params The handler parameters.
   * @param {Object} params.action Redux action object from the NAVIGATE action.
   * @return {string}
   */
  const redirectHandler = ({ action }) => {
    /**
     * When the register url was opened from a login page, a redirect to the original target
     * page needs to happen after a successful registration. It's added by buildRegisterUrl.
     */
    const location = get(action, 'params.state.redirect.location', '');
    return buildRegisterUrl(LEGACY_URL, location);
  };

  subscribe(appWillStart$, () => {
    redirects.set(REGISTER_PATH, redirectHandler, true);
  });
};
