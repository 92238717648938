import {
  configuration,
  appDidStart$,
  logger,
  PIPELINES,
} from '@shopgate/engage/core';
import {
  SHOPGATE_CART_GET_CART,
  SHOPGATE_CART_UPDATE_PRODUCTS,
} from '@shopgate/engage/cart';
import { DEFAULT_ROPE_FULFILLMENT_METHOD, ROPIS } from '@shopgate/engage/locations';
import { SHOPGATE_STOREFRONT_GET_CART, SHOPGATE_STOREFRONT_UPDATE_CART } from '../constants';
import config from '../config';

/**
 * User subscriptions.
 * @param {Function} subscribe The subscribe function.
 */
export default function storefront(subscribe) {
  subscribe(appDidStart$, () => {
    if (config.addCartPipelineHooks === false) {
      return;
    }

    // Set BOPIS as default rope fulfillment method
    configuration.set(DEFAULT_ROPE_FULFILLMENT_METHOD, ROPIS);
    logger.warn(`@shopgate/storefront: Default ROPE fulfillment method set to ${ROPIS}`);

    configuration.update(PIPELINES, pipelines => ({
      ...pipelines,
      [SHOPGATE_CART_GET_CART]: SHOPGATE_STOREFRONT_GET_CART,
      [SHOPGATE_CART_UPDATE_PRODUCTS]: SHOPGATE_STOREFRONT_UPDATE_CART,
    }));

    logger.warn('@shopgate/storefront: Cart pipeline mapping added');
  });
}
