import Plugin from './Plugin';

/**
 * Read the config and create plugin instances
 * @param {Object} clientInformation Information about the current client
 */
export default function init(clientInformation = {}) {
  const { services = [], libVersion = '', state = {} } = clientInformation;
  const { client: { info: { supportedAnalyticsServices: servicesState = [], libVersion: libVersionState = '' } = {} } = {} } = state;

  const trackerSupported = services.concat(servicesState).find(service => ['firebase', 'firebase+gtm'].includes(service));
  const libVersionSupported = parseInt(libVersion || libVersionState, 10) >= 23;

  // Prevent instantiation of the plugin when Firebase is not supported by the app.
  if (!trackerSupported || !libVersionSupported) {
    return;
  }

  // eslint-disable-next-line no-new
  new Plugin();
}
