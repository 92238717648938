import snakeCase from 'lodash.snakecase';
import queryString from 'query-string';
import SgTrackingPlugin from '@shopgate/tracking-core/plugins/Base';
import { SGLink } from '@shopgate/tracking-core/helpers/helper';
import {
  TRACK_PAGEVIEW,
  TRACK_VIEW_CONTENT,
  TRACK_VARIANT_SELECTED,
  TRACK_SET_CAMPAIGN_WITH_URL,
  TRACK_CUSTOM_EVENT,
  TRACK_SELECTED_PAYMENT_INFO,
  TRACK_QR_SCANNER,
  TRACK_AD_SCANNER,
  TRACK_CC_SCANNER,
  TRACK_OPEN_DEEP_LINK,
  TRACK_OPEN_UNIVERSAL_LINK,
  TRACK_OPEN_DEFERRED_DEEP_LINK,
  TRACK_OPEN_PUSH_NOTIFICATION,
  TRACK_LOGIN_SUCCESS,
} from '@shopgate/tracking-core/helpers/events';

/**
 * List of custom events
 * @type {[string]}
 */
export const customEvents = [
  TRACK_QR_SCANNER,
  TRACK_AD_SCANNER,
  TRACK_CC_SCANNER,
  TRACK_OPEN_PUSH_NOTIFICATION,
];

/**
 * List of custom deeplink events
 * @type {[string]}
 */
export const customDeeplinkEvents = [
  TRACK_OPEN_DEEP_LINK,
  TRACK_OPEN_UNIVERSAL_LINK,
  TRACK_OPEN_DEFERRED_DEEP_LINK,
];

export const PARAMETER_NOT_SET = 'not set';

export const ADDITIONAL_PARAMS = 'additionalEventParams';

/**
 * Tracking plugin for Firebase.
 */
class Firebase extends SgTrackingPlugin {
  /**
   * Constructor
   * @param {Object} [options] configuration
   */
  constructor(options = {}) {
    const trackerName = options.trackerName || 'firebase';

    // Invoke the parent constructor
    super(trackerName, {
      ...options,
      useNativeSdk: true,
    });

    this.cmdParams = {
      blacklist: false,
      trackers: [this.trackerName],
    };

    this.registerEvents();
  }

  /**
   * Prepares custom event params (for analyticsLogEvent command)
   * @param {string} name Name of the event
   * @param {Object} data Data of the event
   * @return {{shortName: *, category: *, action, name: *}}
   */
  static getCustomEventParams(name, data) {
    const label = data.eventLabel ? data.eventLabel : name;
    /**
     * Firebase uses snake case as pattern for event names. So the related parameter values need
     * to be converted. In the medium term, "shortName" can be deprecated, since our Firebase
     * integration within the app will replace it with "category".
     */
    return {
      shortName: snakeCase(name),
      category: snakeCase(name),
      action: data.eventAction,
      name: label,
      ...data[ADDITIONAL_PARAMS]
        ? { [ADDITIONAL_PARAMS]: data[ADDITIONAL_PARAMS] }
        : {},
    };
  }

  /**
   * Sends custom event command with given name and params (just event params)
   * @param {string} name Name of the event
   * @param {Object} data Data of the event
   */
  sendCustomEventCommand(name, data) {
    const eventData = Firebase.getCustomEventParams(name, data);
    this.appHandler.logEvent(eventData, this.cmdParams);
  }

  /**
   * Register the plugin for events
   */
  registerEvents() {
    /**
     * Page view which gets data for viewContent and triggers pageView command  with whitelist.
     * @return {boolean} MUST return false so it won't be processed via unified
     */
    this.register[TRACK_PAGEVIEW]((data, raw) => {
      const formattedData = SgTrackingPlugin.formatData('viewContent', raw);

      // Only use the formatted name when it came from the page title.
      formattedData.name = raw && raw.page && raw.page.title ? formattedData.name : '';

      if (formattedData.type === 'product') {
        formattedData.type = 'item';
      }

      this.appHandler.viewContent(formattedData, this.cmdParams);
      return false;
    });

    /**
     * Register for the setCampaignWithUrl event and triggers the appHandler
     * @return {boolean} MUST return false so it won't be processed via unified
     */
    this.register[TRACK_SET_CAMPAIGN_WITH_URL]((data, raw) => {
      const shopgateUrl = new SGLink(data.url);

      if (!shopgateUrl.getParam('utm_source')) {
        shopgateUrl.setParam('utm_source', 'shopgate');
      }

      if (!shopgateUrl.getParam('utm_medium')) {
        shopgateUrl.setParam('utm_medium', raw.type);
      }

      this.appHandler.setCampaignWithUrl({
        url: shopgateUrl.toString(),
      }, this.cmdParams);

      return false;
    });

    /**
     * Handler for the viewContent event. It triggers the logItemview event of the appHandler.
     * @return {boolean} MUST return false so it won't be processed via unified
     */
    this.register[TRACK_VIEW_CONTENT]((data, raw) => {
      if (!this.appHandler.itemView) {
        return false;
      }

      const formattedData = SgTrackingPlugin.formatData('itemView', raw);
      this.appHandler.itemView(formattedData, this.cmdParams);
      return false;
    });

    /**
     * Handler for the variantSelected event. It triggers the logItemView event of the appHandler.
     * @return {boolean} MUST return false so it won't be processed via unified
     */
    this.register[TRACK_VARIANT_SELECTED]((data, raw) => {
      if (!this.appHandler.itemView) {
        return false;
      }

      const formattedData = SgTrackingPlugin.formatData('itemView', raw);
      this.appHandler.itemView(formattedData, this.cmdParams);
      return false;
    });

    /**
     * Handler for the login success event. It triggers the login event of the appHandler.
     * @return {boolean} MUST return false so it won't be processed via unified
     */
    this.register[TRACK_LOGIN_SUCCESS]((data) => {
      if (!this.appHandler.login) {
        return false;
      }

      this.appHandler.login({
        type: data.type || PARAMETER_NOT_SET,
      }, this.cmdParams);
      return false;
    });

    /**
     * Handler for the selected payment info event.
     * @return {boolean} MUST return false so it won't be processed via unified
     */
    this.register[TRACK_SELECTED_PAYMENT_INFO]((data) => {
      if (!this.appHandler.selectedPaymentInfo) {
        return false;
      }

      this.appHandler.selectedPaymentInfo(data, this.cmdParams);
      return false;
    });

    if (typeof this.register[TRACK_CUSTOM_EVENT] === 'function') {
      this.register[TRACK_CUSTOM_EVENT]((data) => {
        this.sendCustomEventCommand(data.eventCategory, data);
        return false;
      });
    }

    /**
     * Custom events starts here
     */
    customEvents.forEach((name) => {
      this.register[name]((data) => {
        this.sendCustomEventCommand(name, data);
        return false;
      });
    });

    /**
     * Custom deeplink events starts here.
     */
    customDeeplinkEvents.forEach((name) => {
      this.register[name]((data) => {
        const { query, url } = queryString.parseUrl(data.eventAction);
        const extendedData = {
          ...data,
          eventAction: url,
        };

        if (Object.keys(query).length > 0) {
          extendedData[ADDITIONAL_PARAMS] = query;
        }

        this.sendCustomEventCommand(name, extendedData);
      });
    });
  }
}

export default Firebase;

// Assign the class to a global variable to enable instantiation in GO.
window.SgFirebaseTracking = Firebase;
