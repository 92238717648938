import {
  PipelineRequest,
  main$,
  appDidStart$,
  logger,
  redirects,
  configuration,
  IS_CONNECT_EXTENSION_ATTACHED,
} from '@shopgate/engage/core';
import { CHECKOUT_PATH, GUEST_CHECKOUT_PATTERN } from '@shopgate/engage/checkout';
import { REGISTER_PATH } from '@shopgate/pwa-common/constants/RoutePaths';
import { OPEN_PUSH_NOTIFICATION } from '@shopgate/pwa-common/constants/ActionTypes';

/**
 * Emits when a push message was opened.
 */
const pushOpened$ = main$
  .filter(({ action }) => action.type === OPEN_PUSH_NOTIFICATION && action.notificationId);

export default (subscribe) => {
  /**
   * Gets triggered when a push was opened.
   */
  subscribe(pushOpened$, ({ action }) => {
    const { notificationId, receivedTime } = action;
    return new PipelineRequest('shopgate.notification.trackMessageOpen')
      .setInput({
        notificationId: notificationId.toString(),
        receivedTime,
      })
      .dispatch()
      .catch(error => logger.error('Could not send push message opened', error));
  });

  subscribe(appDidStart$, () => {
    redirects.set(REGISTER_PATH, REGISTER_PATH, true);
    redirects.set(CHECKOUT_PATH, CHECKOUT_PATH, true);
    redirects.set('/register_legacy_guest', GUEST_CHECKOUT_PATTERN);
    configuration.set(IS_CONNECT_EXTENSION_ATTACHED, true);
  });
};
