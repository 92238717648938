import PipelineRequest from '@shopgate/pwa-core/classes/PipelineRequest';
import { ERROR_HANDLE_SUPPRESS } from '@shopgate/pwa-core/constants/ErrorHandleTypes';

/**
 * Sync legacy user data to connect.
 * @returns {Function}
 */
export const syncUserData = () => () => new PipelineRequest('shopgate.user.syncUserData')
  .setTrusted()
  .setHandleErrors(ERROR_HANDLE_SUPPRESS)
  .dispatch();

/**
 * Gets redirect target for login.
 * @returns {Function}
 */
export const getRedirectTarget = () => () => new PipelineRequest('shopgate.user.getRedirectTarget')
  .setTrusted()
  .setHandleErrors(ERROR_HANDLE_SUPPRESS)
  .dispatch();
