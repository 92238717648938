import PipelineRequest from '@shopgate/pwa-core/classes/PipelineRequest';
import { ERROR_HANDLE_SUPPRESS } from '@shopgate/pwa-core/constants/ErrorHandleTypes';

/**
 * Init tracking meta on pipelines
 * @returns {Function}
 */
export default () => () => new PipelineRequest('shopgate.tracking.initMeta')
  .setTrusted()
  .setHandleErrors(ERROR_HANDLE_SUPPRESS)
  .dispatch();
